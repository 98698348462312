import { complaintUploadPdfEvidence, getUrlForDocumentationUpload } from "./usersBEClient";

export const createDocumentPdf = async (reportNumber: string, clientId: string, documentName: string, blob: Blob) => {
   const updatedComplaint = await getUrlForDocumentationUpload(
      clientId, //Bucket
      `cd/reports/${reportNumber}/docs`, //Folder
      documentName, //fileKey
      reportNumber
   );
   await complaintUploadPdfEvidence(updatedComplaint, blob);
};
