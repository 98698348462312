import { useEffect, useState, useContext } from "react";
import { getUrlForDocumentsList } from "../lib/usersBEClient";
import { ComplaintStepperContext } from "../context/complaintStepperContext";

interface UseGetDocumentationListProps {
   selected: any;
   subject: any;
   isLoading?: boolean;
   open?: boolean;
}

const UseGetDocumentationList = (props: UseGetDocumentationListProps) => {
   const { complaint } = useContext(ComplaintStepperContext);
   const [documentList, setDocumentList] = useState<string[]>([]);
   useEffect(() => {
      const fetchList = async () => {
         if (!complaint.reportNumber) return;
         const involvedEvidences = await getUrlForDocumentsList(complaint.companyBranch.company._id, {
            folder: `cd/reports/${complaint.reportNumber}/evidences/${props.subject}/${
               props.selected?._id || "anonimo"
            }`,
         });
         const files: string[] = [];
         if (involvedEvidences?.Contents) {
            for (const file of involvedEvidences.Contents) {
               files.push(file.Key.split("/").slice(-1));
            }
         }
         setDocumentList(files);
      };
      if (complaint) fetchList();
      // eslint-disable-next-line
   }, [props.open, complaint]);

   return documentList;
};

export default UseGetDocumentationList;
