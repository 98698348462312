import { Box, Typography, Button, CircularProgress, Stack, Tooltip, Popover } from "@mui/material";
import { Form, Formik } from "formik";
import { ClientConfigContext } from "../../../../../context/client/clientConfigContext";
import { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../../../../context/snackBarContext";
import { CreateNewCompany, getUrlS3, useUpdateCompanyPersonDetails } from "../../../../../lib/usersBEClient";
import { uploadFileToS3, UseGetPresignedURLUpload } from "../../../../../lib/s3Client";
import { companyLegalSchema } from "../../../../../lib/Validations/inputSchemas";
import { InputTextField } from "../../../../Inputs/InputTextField";
import { InputFile } from "../../../../Inputs/InputFile";
import { InputImage } from "../../../../Inputs/InputImage";
import { LoadingButton } from "../../../../LoadingButton";
import { ChromePicker } from "react-color";

interface CompanyDetailsComponentProps {
   handleCancel?: Function;
   setCompletedCompany: Function;
   setClientSelected: Function;
   selectedClient: number;
   setValue: Function;
}

export const CompanyDetailsComponentOld = (props: CompanyDetailsComponentProps) => {
   const { clientInfo, getClientInfo, isLoading, refreshClientInfo, group, refreshGroupInfo } =
      useContext(ClientConfigContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [companyLogo, setCompanyLogo] = useState(null);
   const [contractFile, setContractFile] = useState(null);
   const { mutate: createMutate, isLoading: isLoadingCreate } = CreateNewCompany();
   const { mutate: updateMutate, isLoading: isLoadingUpdate } = useUpdateCompanyPersonDetails();
   const [isLoadingUpload, setIsLoadingUpload] = useState(false);
   const [isLoadingFiles, setIsLoadingFiles] = useState(!!clientInfo);
   const [selectedColorPicker, setSelectedColorPicker] = useState("");
   const [primaryColor, setPrimaryColor] = useState("#162c44");
   const [secondaryColor, setSecondaryColor] = useState("#162c44");
   const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
   const open = Boolean(anchorEl);
   const popoverId = open ? "simple-popover" : undefined;

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectedColorPicker(event.currentTarget.id);
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleChangeComplete = (color) => {
      if (selectedColorPicker === "color-picker-1") setPrimaryColor(color.hex);
      else setSecondaryColor(color.hex);
   };

   const getLogo = async (clientId: string) => {
      const logoUrl: string = await getUrlS3("images-lecosy", { folder: clientId }, "logo.png");
      await fetch(logoUrl).then(async (data) => {
         const newBlob = await data.blob();
         const file = new File([newBlob], "logo.png", { type: "image/png" });
         setCompanyLogo(file);
      });
   };
   const getContract = async (clientId: string) => {
      const logoUrl: string = await getUrlS3("files-lecosy", { folder: `contracts/${clientId}` }, "contract.pdf");
      await fetch(logoUrl).then(async (data) => {
         const newBlob = await data.blob();
         const file = new File([newBlob], "contrato.pdf", { type: "application/pdf" });
         setContractFile(file);
      });
      setIsLoadingFiles(false);
   };

   useEffect(() => {
      if (clientInfo && !companyLogo) getLogo(clientInfo._id);
      if (clientInfo && !contractFile) getContract(clientInfo._id);
      // eslint-disable-next-line
   }, [clientInfo]);

   const handleSubmit = async (values) => {
      if (!companyLogo) {
         return showSnackBar("Favor de adjuntar archivos correspondientes", true);
      }
      try {
         if (clientInfo) {
            updateMutate(
               {
                  person_details: {
                     comercialName: values.comercialName,
                     businessName: values.businessName,
                     activity: values.activity,
                  },
                  companyId: clientInfo._id,
               },
               {
                  onSuccess: async (e) => {
                     await handleUploadFiles(clientInfo._id);
                     await refreshClientInfo();
                     showSnackBar("El cliente fue modificado", false);
                  },
                  onError: () => {
                     showSnackBar("Error al modificar el cliente", true);
                  },
               }
            );
         } else {
            createMutate(
               props.selectedClient === 1
                  ? {
                       type: "natural",
                       person_details: {
                          comercialName: values.comercialName,
                          businessName: values.businessName,
                          activity: values.activity,
                       },
                       group: group ? group._id : null,
                    }
                  : {
                       type: "legal",
                       person_details: {
                          comercialName: values.comercialName,
                          businessName: values.businessName,
                          activity: values.activity,
                       },
                       group: group ? group._id : null,
                    },
               {
                  onSuccess: async (e) => {
                     await handleUploadFiles(e._id);
                     await getClientInfo(e._id);
                     props.setCompletedCompany(true);
                     if (group) {
                        refreshGroupInfo();
                     }
                     props.setValue(1);
                     showSnackBar("El cliente fue creado correctamente.", false);
                  },
                  onError: () => {
                     showSnackBar("Error al crear cliente.", true);
                  },
               }
            );
         }
      } catch (error) {
         console.log({ error });
         return;
      }
   };

   const handleUploadFiles = async (clientId: string) => {
      setIsLoadingUpload(true);
      const urlLogo = await UseGetPresignedURLUpload({
         bucket: "images-lecosy",
         folder: clientId,
         name: "logo.png",
      });
      await uploadFileToS3(urlLogo.data, companyLogo.file);
      if (contractFile) {
         const urlContract = await UseGetPresignedURLUpload({
            bucket: "files-lecosy",
            folder: `contracts/${clientId}`,
            name: "contract.pdf",
         });
         await uploadFileToS3(urlContract.data, contractFile.file);
      }
      setIsLoadingUpload(false);
   };

   return (
      <Box sx={{ flex: 1 }}>
         {!isLoading && !isLoadingFiles ? (
            <Formik
               initialValues={{
                  businessName: !isLoading ? clientInfo?.person_details.businessName : "",
                  comercialName: !isLoading ? clientInfo?.person_details.comercialName : "",
                  activity: !isLoading ? clientInfo?.person_details.activity : "",
               }}
               onSubmit={handleSubmit}
               validationSchema={companyLegalSchema}
            >
               <Form>
                  <Box
                     sx={{
                        border: 1,
                        borderColor: "#E0E0E0",
                        borderRadius: 2,
                     }}
                  >
                     <Box sx={{ p: 2 }}>
                        <Typography fontWeight={600} variant="h6">
                           {`Datos de la empresa`}
                        </Typography>
                     </Box>

                     <Box
                        sx={{
                           p: 4,
                           borderTop: 1,
                           borderColor: "#E0E0E0",
                           display: "flex",
                           rowGap: 2,
                           flexDirection: "column",
                           maxHeight: "400px",
                           overflowY: "auto",
                        }}
                     >
                        <InputTextField
                           sx={{ my: 0.5 }}
                           variant="outlined"
                           size="small"
                           name="businessName"
                           id="businessName"
                           type="text"
                           label="Denominación o razon social"
                           fullWidth={true}
                        />

                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="comercialName"
                              id="comercialName"
                              type="text"
                              label="Nombre comercial"
                              fullWidth={true}
                           />
                           <InputTextField
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="activity"
                              id="activity"
                              type="text"
                              label="Actividad y/o giro"
                              fullWidth={true}
                           />
                        </Box>
                        <Box display={"flex"} alignItems={"center"} columnGap={2}>
                           <Typography>Contrato:</Typography>
                           <InputFile file={contractFile?.file} setFile={setContractFile} keyName="contractFile" />
                        </Box>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                           <Box display={"flex"} columnGap={3}>
                              <Typography>Logo de la Empresa:</Typography>
                              <InputImage image={companyLogo?.file} setImage={setCompanyLogo} keyName="companyLogo" />
                           </Box>
                           <Box>
                              <Typography fontWeight={600}>Color corporativo:</Typography>
                              <Stack sx={{ rowGap: 1, mt: 0.5 }}>
                                 <Tooltip title={"Color primario"} placement="right-end">
                                    <Box display={"flex"} sx={{ columnGap: 1, alignItems: "center" }}>
                                       <Button
                                          id="color-picker-1"
                                          aria-describedby={popoverId}
                                          sx={{
                                             borderRadius: 1,
                                             width: 35,
                                             height: 20,
                                             bgcolor: primaryColor,
                                             ":hover": { bgcolor: primaryColor },
                                          }}
                                          onClick={handleClick}
                                       />
                                       <Typography>{primaryColor}</Typography>
                                    </Box>
                                 </Tooltip>

                                 <Tooltip title={"Color secundario"} placement="right-end">
                                    <Box display={"flex"} sx={{ columnGap: 1, alignItems: "center" }}>
                                       <Button
                                          id="color-picker-2"
                                          aria-describedby={popoverId}
                                          sx={{
                                             borderRadius: 1,
                                             maxWidth: 35,
                                             height: 20,
                                             bgcolor: secondaryColor,
                                             ":hover": { bgcolor: secondaryColor },
                                          }}
                                          onClick={handleClick}
                                       />
                                       <Typography>{secondaryColor}</Typography>
                                    </Box>
                                 </Tooltip>
                              </Stack>
                           </Box>
                           <Popover
                              id={popoverId}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                 vertical: "bottom",
                                 horizontal: "left",
                              }}
                           >
                              <ChromePicker
                                 color={selectedColorPicker === "color-picker-1" ? primaryColor : secondaryColor}
                                 onChangeComplete={handleChangeComplete}
                                 disableAlpha={true}
                              />
                           </Popover>
                        </Stack>
                     </Box>
                  </Box>
                  <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                     <Button
                        sx={{ height: "100%" }}
                        variant="outlined"
                        onClick={() => (group === undefined ? props.setClientSelected(0) : props.handleCancel())}
                     >
                        Cancelar
                     </Button>
                     <LoadingButton
                        label={clientInfo ? "Guardar cambios" : "Guardar"}
                        isLoading={isLoadingCreate || isLoadingUpdate || isLoadingUpload}
                     />
                  </Box>
               </Form>
            </Formik>
         ) : (
            <Box
               sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
               }}
            >
               <CircularProgress size={24} />
            </Box>
         )}
      </Box>
   );
};
