import { useContext, useEffect, useState } from "react";
import clasifications from "../../const/clasifications.json";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Collapse, Grid, MenuItem, Typography, CircularProgress, Stack } from "@mui/material";
import {
   updateProceededComplaintInfo,
   updateDeclinedComplaintInfo,
   complaintUploadDocumentation,
   changeIncomingComplaint,
   sendFairnessEmail,
} from "../../lib/lecosyBackendClient";
import { addDashesToNumber, getDateFromISODate, getMonthWord } from "../../const/globalConst";
import {
   ddInterposicionDeDenuncia,
   declinedComplaint,
   proceededComplaint,
} from "../../lib/DocmunetsTemplates/InterposicionDeDenunia";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { ComplainantAndWitnessCards } from "../../components/ComplainantAndWitnessCards";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Container from "@mui/material/Container/Container";
import { ComplaintContext } from "../../context/complaintsExternContext/complaintContext";
import { TitleAppBar } from "../../components/TitleAppBar";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import { SnackBarContext } from "../../context/snackBarContext";
import { InputTextField } from "../../components/Inputs/InputTextField";
import { InputSelectField } from "../../components/Inputs/InputSelectField";
import { Form, Formik, FormikProps } from "formik";
import { proceedComplaintReviewSchema, proceedNiggleSugestionReviewSchema } from "../../lib/Validations/inputSchemas";
import { getUrlForDocumentationUpload } from "../../lib/usersBEClient";
import { UserContext } from "../../context/UserContext";
import VerifyUserSession from "../../hooks/verifyUserSession";
import EvidenceTable from "../../components/Evidences/EvidenceTable";
import UseGetDocumentationList from "../../hooks/UseGetDocumentationList";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const ComplaintReviewScreen = ({ intern }) => {
   VerifyUserSession();
   const navigate = useNavigate();
   const { reportNumber } = useParams();
   const { getComplaint, complaint, isLoading, setIsLoading } = useContext(ComplaintStepperContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { refetch } = useContext(ComplaintContext);
   const [openRejectConfirmation, setOpenRejectConfirmation] = useState(false);
   const [openProceedConfirmation, setOpenProceedConfirmation] = useState(false);
   const [isLoadingSent, setIsLoadingSent] = useState(false);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission(intern ? "Denuncias internas" : "Denuncias externas");

   useEffect(() => {
      const fetchComplaintByReportNumber = async () => {
         try {
            getComplaint(reportNumber);
            if (complaint.incoming) {
               await changeIncomingComplaint(reportNumber);
               refetch();
               setIsLoading(false);
            }
         } catch (error: any) {}
      };
      setIsLoading(true);
      fetchComplaintByReportNumber();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const capitalizeFirstLetter = (field: any) => {
      const capitalizedText = field.charAt(0).toUpperCase() + field.slice(1);
      return capitalizedText;
   };
   const date = new Date().toISOString();

   const handleComplaintReview = async (values) => {
      setIsLoadingSent(true);
      createAndUploadProcedentePdf(values.city, values.state);
      createAndUploadInterposicionDeDenuncia(
         values.observations,
         complaint.type ?? "denuncia",
         values.clasification,
         "Procedente"
      );
      await updateProceededComplaintInfo(complaint.reportNumber, values.clasification);
      refetch();
      showSnackBar(`${capitalizeFirstLetter(complaint?.type || "denuncia")} procesada y enviada al cliente.`, false);
      setTimeout(() => {
         setIsLoadingSent(false);
         navigate(-1);
      }, 1000);
   };

   const handleComplaintRejection = async (values) => {
      setIsLoadingSent(true);
      createImprocedentePdf(values.city, values.state, values.observations);
      createAndUploadInterposicionDeDenuncia(
         values.observations,
         complaint.type ?? "denuncia",
         values.clasification,
         "Improcedente"
      );
      await updateDeclinedComplaintInfo(complaint.reportNumber, values.observations);
      refetch();
      showSnackBar("Denuncia improcedente y enviada a papelera.", false);
      setTimeout(() => {
         setIsLoadingSent(false);
         navigate(-1);
      }, 2000);
   };

   const createAndUploadInterposicionDeDenuncia = (
      observations: string,
      type: string,
      clasification: string,
      procedent: string
   ) => {
      const complainerName =
         complaint.complainerDetails?.name && complaint.complainerDetails?.lastName
            ? `${complaint.complainerDetails.name} ${complaint.complainerDetails.lastName}`
            : "No aplica";
      const complainerAddress = complaint.complainerDetails?.address
         ? complaint.complainerDetails.address
         : "No aplica";
      const complainerPhoneNumber = complaint.complainerDetails?.phoneNumber
         ? complaint.complainerDetails.phoneNumber
         : "No aplica";
      const complainerEmail = complaint.complainerDetails?.email ? complaint.complainerDetails.email : "No aplica";

      try {
         const InterpocisionDD = ddInterposicionDeDenuncia(
            procedent,
            complaint.reportNumber,
            complainerName,
            complaint.offenderDetails ?? [],
            complaint.complaintType,
            complaint.category,
            complainerAddress,
            complaint.complainerType,
            complaint.directlyAffected,
            complainerPhoneNumber,
            complainerEmail,
            complaint.witnesses,
            complaint.date ?? complaint.createdAt,
            complaint.specificPlace || "",
            complaint.body,
            "En proceso",
            observations,
            clasification,
            date,
            type
         );
         const pdfDocGenerator = pdfMake.createPdf(InterpocisionDD);
         pdfDocGenerator.getBlob(async (blob) => {
            const urlResponse = await getUrlForDocumentationUpload(
               complaint.companyBranch.company._id,
               `cd/reports/${complaint.reportNumber}/docs`,
               `interposicion-${complaint.reportNumber}`,
               complaint.reportNumber
            );
            await complaintUploadDocumentation(urlResponse, blob);
         });
      } catch (err) {
         return console.log(err);
         // return showSnackBar("Error al crear interposición")
      }
   };

   const createAndUploadProcedentePdf = (city: string, state: string) => {
      const ddProcedente = proceededComplaint(
         city,
         state,
         complaint.companyBranch.company.person_details.comercialName,
         complaint.createdAt,
         complaint.reportNumber,
         complaint.type || "denuncia"
      );
      const pdfDocGenerator = pdfMake.createPdf(ddProcedente);
      pdfDocGenerator.getBlob(async (blob) => {
         console.log({ complaint });
         const urlResponse = await getUrlForDocumentationUpload(
            complaint.companyBranch.company._id,
            `cd/reports/${complaint.reportNumber}/docs`,
            `notificacion-procedente-${complaint.reportNumber}`,
            complaint.reportNumber
         );
         await complaintUploadDocumentation(urlResponse, blob);
      });
      pdfDocGenerator.getBase64(async (buffer) => {
         await sendFairnessEmail("Procedente", complaint.followUpEmail, complaint.reportNumber, buffer);
      });
   };

   const createImprocedentePdf = (city: string, state: string, observations: string) => {
      const ddImprocedente = declinedComplaint(
         city,
         state,
         complaint.companyBranch.company.person_details.comercialName,
         complaint.createdAt,
         observations,
         complaint.type || "denuncia"
      );
      const pdfDocGenerator = pdfMake.createPdf(ddImprocedente);
      pdfDocGenerator.getBlob(async (blob) => {
         const urlResponse = await getUrlForDocumentationUpload(
            complaint.companyBranch.company._id,
            `cd/reports/${complaint.reportNumber}/docs`,
            `notificacion-improcedente-${complaint.reportNumber}`,
            complaint.reportNumber
         );
         await complaintUploadDocumentation(urlResponse, blob);
      });
      pdfDocGenerator.getBase64(async (buffer) => {
         await sendFairnessEmail("Improcedente", complaint.followUpEmail, complaint.reportNumber, buffer);
      });
   };

   const noDeleteHeaders = [
      { field: "file", headerName: "Evidencia" },
      { field: "icon", headerName: "Ver" },
   ];

   const documentList = UseGetDocumentationList({
      selected: complaint?.complainerDetails ? complaint?.complainerDetails?._id : undefined,
      subject: "Denunciado",
   });

   return (
      <Box p={2}>
         <TitleAppBar
            head={`Revisión de ${complaint?.type || "denuncia"} ${reportNumber}`}
            isScheduleAppointment={false}
         />
         <Container maxWidth="xl">
            {!isLoading ? (
               <Box sx={{ p: 2 }}>
                  {complaint ? (
                     <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                           <Box sx={{ bgcolor: "white", boxShadow: "0px 2px 4px #00000040" }}>
                              <Grid container>
                                 <Grid item xs={2.4}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <Typography fontWeight={600} fontSize={16} textAlign={"center"}>
                                          Fecha:
                                       </Typography>
                                       <Typography variant="subtitle1" textAlign={"center"}>{`${date
                                          .toString()
                                          .slice(8, 10)} ${getMonthWord(date.toString().slice(5, 7))} ${date
                                          .toString()
                                          .slice(0, 4)}`}</Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={2.4}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <Typography fontWeight={600} fontSize={16} textAlign={"center"}>
                                          Empresa/Sucursal:
                                       </Typography>
                                       <Typography variant="subtitle1" textAlign={"center"}>
                                          {complaint.companyBranch.name ||
                                             complaint.companyBranch.company.person_details.comercialName}
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={2.4}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <Typography fontWeight={600} fontSize={16} textAlign={"center"}>
                                          {`${capitalizeFirstLetter(complaint?.type || "denuncia")} anónima:`}
                                       </Typography>
                                       <Typography variant="subtitle1" textAlign={"center"}>
                                          {complaint.complaintType === "anonima" ? "Si" : "No"}
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={2.4}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <Typography fontWeight={600} fontSize={16} textAlign={"center"}>
                                          Categoría:
                                       </Typography>
                                       <Typography variant="subtitle1" textAlign={"center"}>
                                          {capitalizeFirstLetter(complaint.category || "no aplica")}
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={2.4}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <Typography fontWeight={600} fontSize={16} textAlign={"center"}>
                                          Número de reporte:
                                       </Typography>
                                       <Typography variant="subtitle1" textAlign={"center"}>
                                          {complaint.reportNumber}
                                       </Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Grid>
                        <Grid item xs={12} md={documentList.length > 0 ? 4.5 : 6} display={"flex"}>
                           <Box
                              sx={{
                                 bgcolor: "white",
                                 boxShadow: "0px 2px 4px #00000040",
                                 p: 2,
                                 height: "auto",
                                 flexGrow: 1,
                              }}
                           >
                              <Grid container rowSpacing={1} flex={4} flexGrow={1}>
                                 <Grid item xs={12}>
                                    <Typography variant="h6" color={"#2d4357"} fontWeight={600}>
                                       Datos generales
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Box display={"flex"}>
                                       <Typography variant="body1" textAlign="center">
                                          <strong style={{ fontWeight: 600 }}>Pseudónimo: </strong>
                                          {complaint.pseudonym}
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Box display={"flex"}>
                                       <Typography variant="subtitle1">
                                          <strong style={{ fontWeight: 600 }}>Lugar de los hechos: </strong>
                                          {capitalizeFirstLetter(complaint.specificPlace || "No aplica")}
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 {complaint.date && (
                                    <Grid item xs={12}>
                                       <Box display={"flex"}>
                                          <Typography>
                                             <strong style={{ fontWeight: 600 }}>Fecha de los hechos: </strong>
                                             {`${complaint.date.toString().slice(8, 10)} ${getMonthWord(
                                                complaint.date.toString().slice(5, 7)
                                             )} ${complaint.date.toString().slice(0, 4)}`}
                                          </Typography>
                                       </Box>
                                    </Grid>
                                 )}
                                 <Grid item xs={12}>
                                    <Box display={"flex"} flexDirection="column">
                                       <Typography variant="body1" fontWeight={600}>
                                          {`Descripción de la ${complaint?.type || "denuncia"}:`}
                                       </Typography>
                                       <Typography
                                          variant="subtitle1"
                                          sx={{
                                             textAlign: "justify",
                                             maxHeight: "120px",
                                             overflowY: "auto",
                                          }}
                                       >
                                          {complaint.body}
                                       </Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Grid>
                        <Grid item xs={12} md={documentList.length > 0 ? 4.5 : 6} display={"flex"}>
                           <Box
                              sx={{
                                 bgcolor: "white",
                                 boxShadow: "0px 2px 4px #00000040",
                                 p: 2,
                                 height: "auto",
                                 flexGrow: 1,
                              }}
                           >
                              <Grid container rowSpacing={1}>
                                 <Grid item xs={12}>
                                    <Typography variant="h6" color={"#2d4357"} fontWeight={600}>
                                       Datos del interpositor
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={12} md={12}>
                                    <Box display={"flex"}>
                                       <Typography variant="body1" fontWeight={400} sx={{ textAlign: "center" }}>
                                          <strong style={{ fontWeight: 600 }}>Nombre: </strong>
                                          {complaint.complainerDetails
                                             ? `${complaint.complainerDetails.name} ${complaint.complainerDetails.lastName}`
                                             : "No Aplica"}
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <Box display={"flex"}>
                                       <Typography variant="body1" fontWeight={400} textAlign="center">
                                          <strong style={{ fontWeight: 600 }}>Género: </strong>
                                          {complaint.complainerDetails
                                             ? `${complaint.complainerDetails.gender}`
                                             : "No Aplica"}
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <Box display={"flex"}>
                                       <Typography variant="body1" textAlign="center">
                                          <strong style={{ fontWeight: 600 }}>Correo: </strong>
                                          {complaint.complainerDetails
                                             ? complaint.complainerDetails.email
                                             : "No Aplica"}
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <Box display={"flex"}>
                                       <Typography variant="body1" textAlign="center">
                                          <strong style={{ fontWeight: 600 }}>Celular: </strong>
                                          {complaint.complainerDetails
                                             ? addDashesToNumber(complaint.complainerDetails.phoneNumber)
                                             : "No Aplica"}
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <Box display={"flex"}>
                                       <Typography variant="body1" fontWeight={400}>
                                          <strong style={{ fontWeight: 600 }}>Tipo de interpositor: </strong>
                                          {capitalizeFirstLetter(complaint.complainerType)}
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <Box display={"flex"}>
                                       <Typography variant="body1" fontWeight={400}>
                                          <strong style={{ fontWeight: 600 }}>Se le afectó directamente: </strong>
                                          {complaint.directlyAffected ? "Si" : "No"}
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Box display={"flex"}>
                                       <Typography variant="body1" fontWeight={400}>
                                          <strong style={{ fontWeight: 600 }}>Dirección: </strong>
                                          {complaint.complainerDetails
                                             ? `${
                                                  complaint.complainerDetails.address
                                                     ? complaint.complainerDetails.address + ", "
                                                     : ""
                                               } ${
                                                  complaint.complainerDetails.zipCode
                                                     ? complaint.complainerDetails.zipCode + ", "
                                                     : ""
                                               } ${complaint.complainerDetails.city}, ${
                                                  complaint.complainerDetails.state
                                               }`
                                             : "No Aplica"}
                                       </Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Grid>
                        {documentList.length > 0 ? (
                           <Grid item xs={3}>
                              <Box
                                 sx={{
                                    bgcolor: "white",
                                    boxShadow: "0px 2px 4px #00000040",
                                    p: 2,
                                    minHeight: 315,
                                    height: "auto",
                                 }}
                              >
                                 {documentList.length > 0 ? (
                                    <Box
                                       sx={{
                                          overflowY: "auto",
                                          maxHeight: "100%",
                                       }}
                                    >
                                       <EvidenceTable
                                          rows={documentList}
                                          headers={noDeleteHeaders}
                                          reportNumber={complaint.reportNumber}
                                          subject={"Denunciado"}
                                          selectedUser={complaint.complainerDetails?._id || undefined}
                                       />
                                    </Box>
                                 ) : (
                                    <Box display={"flex"} sx={{ alignItems: "flex-start", flexDirection: "column" }}>
                                       <Typography variant="body1" fontWeight={600} sx={{ textAlign: "center" }}>
                                          Evidencia(s):&nbsp;
                                       </Typography>
                                       No existen evidencias
                                    </Box>
                                 )}
                              </Box>
                           </Grid>
                        ) : (
                           <></>
                        )}
                        {(complaint.type || "denuncia") === "denuncia" && (
                           <Grid item xs={12} md={6}>
                              <Box sx={{ bgcolor: "white", boxShadow: "0px 2px 4px #00000040", p: 2 }}>
                                 <Grid container rowSpacing={1}>
                                    <Grid item xs={12}>
                                       <Typography variant="h6" color={"#2d4357"} fontWeight={600}>
                                          {complaint.offenderDetails.length > 1
                                             ? "Datos de los infractores"
                                             : "Datos del infractor"}
                                       </Typography>
                                    </Grid>
                                 </Grid>
                                 <>
                                    {complaint.offenderDetails.length !== 0 ? (
                                       <ComplainantAndWitnessCards info={complaint.offenderDetails} isWitness={false} />
                                    ) : (
                                       "No existen infractores"
                                    )}
                                 </>
                              </Box>
                           </Grid>
                        )}
                        {(complaint.type || "denuncia") === "denuncia" && (
                           <Grid item xs={12} md={6}>
                              <Box sx={{ bgcolor: "white", boxShadow: "0px 2px 4px #00000040", p: 2 }}>
                                 <Grid container rowSpacing={1}>
                                    <Grid item xs={12}>
                                       <Typography variant="h6" color={"#2d4357"} fontWeight={600}>
                                          {complaint.witnesses.length > 1
                                             ? "Datos de los testigos"
                                             : "Datos del testigo"}
                                       </Typography>
                                    </Grid>
                                 </Grid>
                                 <>
                                    {complaint.witnesses.length !== 0 ? (
                                       <ComplainantAndWitnessCards info={complaint.witnesses} isWitness={true} />
                                    ) : (
                                       "No existen testigos"
                                    )}
                                 </>
                              </Box>
                           </Grid>
                        )}
                        <Grid item xs={12} md={12}>
                           <Formik
                              initialValues={{
                                 calification: "",
                                 observations: "",
                                 clasification: "",
                                 city: "",
                                 state: "",
                              }}
                              onSubmit={(values) => {
                                 if (values.calification === "Procedente") {
                                    setOpenProceedConfirmation(true);
                                 }
                                 if (values.calification === "Improcedente") {
                                    setOpenRejectConfirmation(true);
                                 }
                              }}
                              validationSchema={
                                 complaint.type && complaint.type !== "denuncia"
                                    ? proceedNiggleSugestionReviewSchema
                                    : proceedComplaintReviewSchema
                              }
                           >
                              {(formProps: FormikProps<any>) => (
                                 <Form>
                                    <Box sx={{ bgcolor: "white", boxShadow: "0px 2px 4px #00000040", p: 2 }}>
                                       <Grid container spacing={1}>
                                          <Grid item xs={12}>
                                             <Typography variant="h6" color={"#2d4357"} fontWeight={600}>
                                                Reporte lecosy
                                             </Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                             <Box display={"flex"}>
                                                <Typography variant="body1" sx={{ textAlign: "center" }}>
                                                   <strong style={{ fontWeight: 600 }}>Empresa: </strong>
                                                   {complaint.companyBranch.company.person_details.comercialName}
                                                </Typography>
                                             </Box>
                                          </Grid>
                                          <Grid item xs={12}>
                                             <Box display={"flex"}>
                                                <Typography variant="body1" sx={{ textAlign: "center" }}>
                                                   <strong style={{ fontWeight: 600 }}>Estatus del reporte: </strong>
                                                   {capitalizeFirstLetter(complaint.status)}
                                                </Typography>
                                             </Box>
                                          </Grid>
                                          <Grid item xs={12}>
                                             <Box display={"flex"}>
                                                <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                                                   <strong style={{ fontWeight: 600 }}>Fecha de interposicion: </strong>
                                                   {getDateFromISODate(complaint.createdAt)}
                                                </Typography>
                                             </Box>
                                          </Grid>
                                          <Grid item xs={12}>
                                             <Stack spacing={1}>
                                                <Typography variant="body1" fontWeight={600}>
                                                   Calificación de la interposición
                                                </Typography>
                                                <InputSelectField
                                                   fullWidth
                                                   id="calification"
                                                   name="calification"
                                                   label="Calificación"
                                                   labelId="Calificación"
                                                   disabled={access}
                                                >
                                                   {["Procedente", "Improcedente"].map((a) => (
                                                      <MenuItem value={a} key={a}>
                                                         {a}
                                                      </MenuItem>
                                                   ))}
                                                </InputSelectField>
                                             </Stack>
                                          </Grid>
                                          <Grid item xs={12}>
                                             <Collapse in={formProps.values.calification === "Improcedente"}>
                                                <Stack spacing={1}>
                                                   <Typography variant="body1" fontWeight={600}>
                                                      Observaciones Lecosy
                                                   </Typography>
                                                   <InputTextField
                                                      fullWidth
                                                      id="observations"
                                                      name="observations"
                                                      type="text"
                                                      variant="outlined"
                                                      label="Observaciones"
                                                      disabled={access}
                                                   />
                                                </Stack>
                                             </Collapse>
                                          </Grid>
                                          {(complaint.type === undefined || complaint.type === "denuncia") && (
                                             <Grid item xs={12}>
                                                <Collapse in={formProps.values.calification === "Procedente"}>
                                                   <Stack spacing={1}>
                                                      <Typography variant="body1" fontWeight={600}>
                                                         Clasificación
                                                      </Typography>
                                                      <InputSelectField
                                                         fullWidth
                                                         id="clasification"
                                                         name="clasification"
                                                         label="Clasificación"
                                                         labelId="Clasificación"
                                                         disabled={access}
                                                      >
                                                         {clasifications[complaint.category].map((a) => (
                                                            <MenuItem value={a} key={a}>
                                                               {a}
                                                            </MenuItem>
                                                         ))}
                                                      </InputSelectField>
                                                   </Stack>
                                                </Collapse>
                                             </Grid>
                                          )}
                                          <Grid item xs={12}>
                                             <Typography variant="body1" fontWeight={600}>
                                                Ciudad y estado en donde se realizó la revisión
                                             </Typography>
                                             <Box></Box>
                                          </Grid>
                                          <Grid item xs={6}>
                                             <InputTextField
                                                fullWidth
                                                id="city"
                                                name="city"
                                                type="text"
                                                variant="outlined"
                                                label="Ciudad"
                                                disabled={access}
                                             />
                                          </Grid>
                                          <Grid item xs={6}>
                                             <InputTextField
                                                fullWidth
                                                id="state"
                                                name="state"
                                                type="text"
                                                variant="outlined"
                                                label="Estado"
                                                disabled={access}
                                             />
                                          </Grid>
                                       </Grid>
                                    </Box>
                                    {!access ? (
                                       <Box display={"flex"} sx={{ justifyContent: "flex-end" }}>
                                          <Button variant="contained" sx={{ mt: 2 }} type="submit" value="enviar">
                                             {!isLoadingSent ? "Enviar" : <CircularProgress size={22} />}
                                          </Button>
                                       </Box>
                                    ) : null}
                                    {/* Reject */}
                                    <ConfirmationModal
                                       open={openRejectConfirmation}
                                       setOpen={setOpenRejectConfirmation}
                                       title={`Declarar ${complaint?.type || "denuncia"} como improcedente`}
                                       body={
                                          <>
                                             <Typography>{`Esta ${
                                                complaint?.type || "denuncia"
                                             } se moverá al Banco de Consultas.`}</Typography>
                                             <Typography>Este movimiento es irreversible.</Typography>
                                          </>
                                       }
                                       onConfirm={() => {
                                          handleComplaintRejection(formProps.values);
                                       }}
                                    />
                                    {/* Proceed */}
                                    <ConfirmationModal
                                       open={openProceedConfirmation}
                                       setOpen={setOpenProceedConfirmation}
                                       title={`Declarar ${complaint?.type || "denuncia"} como procedente`}
                                       body={
                                          <>
                                             <Typography>{`Esta ${
                                                complaint?.type || "denuncia"
                                             } se considerará como activa.`}</Typography>
                                             <Typography>Este movimiento es irreversible.</Typography>
                                          </>
                                       }
                                       onConfirm={() => {
                                          handleComplaintReview(formProps.values);
                                       }}
                                    />
                                 </Form>
                              )}
                           </Formik>
                        </Grid>
                     </Grid>
                  ) : (
                     <Typography textAlign={"center"}>Error al cargar la denuncia</Typography>
                  )}
               </Box>
            ) : (
               <Box display={"flex"} sx={{ flex: 1, justifyContent: "center", pt: 2 }}>
                  <CircularProgress />
               </Box>
            )}
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   p: 1,
};
