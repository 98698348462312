import { useState, useEffect, useContext } from "react";
import { Box, Divider, Stack, Grid, Typography, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getDateFromISODate } from "../../const/globalConst";
import ErrorIcon from "@mui/icons-material/Error";
import { ClientConfigContext } from "../../context/client/clientConfigContext";
import { UseLastCompanyInvoice, findStatusService } from "../../lib/usersBEClient";
import { useParams } from "react-router-dom";
import { type Invoice } from "../../types/BaseTypes";

export const ServiceStatusComponent = () => {
   const [isActive, setIsActive] = useState(false);
   const [isDueSoon, setIsDueSoon] = useState(false);
   const { clientInfo } = useContext(ClientConfigContext);
   const [statusService, setStatusService] = useState<boolean>(null);
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const { clientId } = useParams();
   const [invoiceData, setInvoiceData] = useState<Invoice>();

   const fetchData = async () => {
      const data = await UseLastCompanyInvoice(clientId);
      setInvoiceData(data);
      const status = await findStatusService(clientInfo.company_details._id);
      setStatusService(status);

      const endPeriodDate = new Date(data.endPeriod);
      const currentDate = new Date();
      const nextDueDate = new Date(data.endPeriod);
      nextDueDate.setDate(nextDueDate.getDate() + 4);

      if (currentDate.getTime() <= endPeriodDate.getTime()) {
         setIsActive(true);
      } else {
         if (currentDate.getTime() < nextDueDate.getTime()) {
            setIsActive(true);
            setIsDueSoon(true);
         } else {
            setIsActive(false);
            setIsDueSoon(false);
         }
      }
      setIsLoading(false);
   };

   useEffect(() => {
      setIsLoading(true);
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [clientInfo]);

   return (
      <>
         <Box sx={{ borderBottom: 1, borderColor: "divider" }} bgcolor={"#f9f9f9"} borderRadius={1} boxShadow={2} p={2}>
            <Box>
               <Typography fontWeight={600}>Estatus del servicio</Typography>
            </Box>
            <Box>
               <Divider sx={{ mt: 2 }} />
            </Box>

            {!isLoading ? (
               !invoiceData ? (
                  <Stack
                     direction={"row"}
                     display={"flex"}
                     justifyContent={"space-between"}
                     py={2}
                     px={4}
                     alignItems={"center"}
                  >
                     <Grid>
                        <Box>Fecha de corte: </Box>
                     </Grid>
                     <Grid>
                        <Box sx={{ maxWidth: "250px" }}>Inhabilitado</Box>
                     </Grid>
                     <Grid>
                        <Box>
                           <CancelIcon sx={{ width: "35px", height: "35px", color: "#f70a0a" }} />
                        </Box>
                     </Grid>
                  </Stack>
               ) : (
                  <Stack
                     direction={"row"}
                     display={"flex"}
                     justifyContent={"space-between"}
                     p={4}
                     alignItems={"center"}
                  >
                     <Grid>
                        <Box>Fecha de corte:</Box>
                        <Box>{statusService ? getDateFromISODate(invoiceData.endPeriod) : ""}</Box>
                     </Grid>
                     <Grid>
                        <Box sx={{ maxWidth: "250px" }}>
                           {statusService
                              ? isActive && isDueSoon
                                 ? "Fecha limite de pago proxima"
                                 : isActive && isDueSoon === false
                                 ? "Habilitado"
                                 : "Inhabilitado"
                              : "Inhabilitado"}
                        </Box>
                     </Grid>
                     <Grid>
                        <Box>
                           {statusService ? (
                              isActive && isDueSoon ? (
                                 <ErrorIcon sx={{ width: "35px", height: "35px", color: "#daf202" }} />
                              ) : isActive && isDueSoon === false ? (
                                 <CheckCircleIcon sx={{ width: "35px", height: "35px", color: "#0FA958" }} />
                              ) : (
                                 <CancelIcon sx={{ width: "35px", height: "35px", color: "#f70a0a" }} />
                              )
                           ) : (
                              <CancelIcon sx={{ width: "35px", height: "35px", color: "#f70a0a" }} />
                           )}
                        </Box>
                     </Grid>
                  </Stack>
               )
            ) : (
               <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                  <CircularProgress size={24} />
               </Box>
            )}
         </Box>
      </>
   );
};
