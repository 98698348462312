import { useState, useEffect, useContext } from "react";
import { Box, Button, Grid, Typography, Divider, CircularProgress, Container } from "@mui/material";
import { complainantInterview } from "../../../../lib/DocmunetsTemplates/InterviewTemplates";
import { createDocumentPdf } from "../../../../lib/uploadDocuments";
import { getComplaintsByReportNumber } from "../../../../lib/lecosyBackendClient";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik, FormikProps } from "formik";
import { complainantInterviewSchema } from "../../../../lib/Validations/inputSchemas";
import { InputTextField } from "../../../../components/Inputs/InputTextField";
import { InputRadioButton } from "../../../../components/Inputs/InputRadioButton";
import { TitleAppBar } from "../../../../components/TitleAppBar";
import { SnackBarContext } from "../../../../context/snackBarContext";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import pdfMake from "../../../../const/pdfMakeFonts";
import { UserContext } from "../../../../context/UserContext";

export const ComplainantInterview = () => {
   const { reportNumber } = useParams();
   const { showSnackBar } = useContext(SnackBarContext);
   const [uploadedLogoBlob, setUploadedLogoBlob] = useState<File>();
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [complaint, setComplaint] = useState({
      _id: "",
      body: "",
      reportNumber: "",
      documentation: [],
      companyBranch: null,
   });
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const navigate = useNavigate();

   useEffect(() => {
      if (access) {
         navigate("/inicio");
      }
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      const fetchComplaintByReportNumber = async () => {
         const complaintResponse = await getComplaintsByReportNumber(reportNumber);
         setComplaint(complaintResponse);
      };
      fetchComplaintByReportNumber();
      // eslint-disable-next-line
   }, []);

   const handleSendForm = (values, actions) => {
      setIsLoading(true);
      let base64data: string | ArrayBuffer = "";
      if (values.online === "Si" && !uploadedLogoBlob) {
         showSnackBar("Favor de subir la imagen de evidencia.", true);
         return null;
      } else if (values.online === "Si" && uploadedLogoBlob) {
         const reader = new FileReader();
         reader.readAsDataURL(uploadedLogoBlob);
         reader.onloadend = function () {
            base64data = reader.result;
            const dd = complainantInterview(
               false,
               values.complainantName,
               values.complainantLabors,
               values.complainantActivity,
               values.complainantTime,
               values.complainantExplanation,
               values.complainantIsComplainter,
               values.complainantInvolved,
               values.complainantInvolvedNarration,
               values.complainantConfirmation,
               values.complainantWitness,
               values.complainantWitnessAnswer,
               values.complainantMainVictim,
               values.complainantEvidence,
               values.complainantGivesEvidence,
               values.complainantMeasures,
               values.complainantFixDamage,
               values.complainantTruth,
               base64data
            );
            const pdfDocGenerator = pdfMake.createPdf(dd);
            pdfDocGenerator.getBlob(async (blob) => {
               if (complaint && complaint.documentation) {
                  if (
                     complaint.documentation.some((e) => e === `entrevista-denunciante-${complaint.reportNumber}.pdf`)
                  ) {
                     showSnackBar("Este documento ya fue enviado.", true);
                     setIsLoading(false);
                     setTimeout(() => navigate(-1), 1000);
                     return null;
                  }
               } else {
                  showSnackBar("Error: No se pudo verificar el documento.", true);
                  setIsLoading(false);
                  return; // Asegurarse de salir de la función si hay un error
               }

               if (complaint && complaint.reportNumber) {
                  try {
                     await createDocumentPdf(
                        complaint.reportNumber,
                        complaint.companyBranch.company._id,
                        `entrevista-denunciante-${complaint.reportNumber}`,
                        blob
                     );
                  } catch (error) {
                     showSnackBar("Error: No se pudo crear el documento PDF.", true);
                     setIsLoading(false);
                  }
               } else {
                  showSnackBar("Error: No se pudo crear el documento PDF.", true);
                  setIsLoading(false);
               }
               showSnackBar("Documento creado y subido correctamente.", false);
               setTimeout(() => navigate(-1), 1000);
               actions.resetForm();
               setIsLoading(false);
            });
         };
      } else {
         const dd = complainantInterview(
            false,
            values.complainantName,
            values.complainantLabors,
            values.complainantActivity,
            values.complainantTime,
            values.complainantExplanation,
            values.complainantIsComplainter,
            values.complainantInvolved,
            values.complainantInvolvedNarration,
            values.complainantConfirmation,
            values.complainantWitness,
            values.complainantWitnessAnswer,
            values.complainantMainVictim,
            values.complainantEvidence,
            values.complainantGivesEvidence,
            values.complainantMeasures,
            values.complainantFixDamage,
            values.complainantTruth,
            ""
         );
         pdfMake.createPdf(dd).open();
         setTimeout(() => navigate(-1), 1000);
      }
   };

   const handleOpenEmptyInterview = () => {
      const dd = complainantInterview(true, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "");
      pdfMake.createPdf(dd).open();
      setTimeout(() => navigate(-1), 1000);
   };

   return (
      <Box p={2}>
         <TitleAppBar head="Entrevista a denunciante" isScheduleAppointment={true} />
         <Container maxWidth="xl">
            <Formik
               initialValues={{
                  online: "",
                  complainantName: "",
                  complainantLabors: "",
                  complainantActivity: "",
                  complainantTime: "",
                  complainantExplanation: "",
                  complainantIsComplainter: "",
                  complainantInvolved: "",
                  complainantInvolvedNarration: "",
                  complainantConfirmation: "",
                  complainantWitness: "",
                  complainantWitnessAnswer: "",
                  complainantMainVictim: "",
                  complainantEvidence: "",
                  complainantGivesEvidence: "",
                  complainantMeasures: "",
                  complainantFixDamage: "",
                  complainantTruth: "",
               }}
               validationSchema={complainantInterviewSchema}
               validateOnChange={false}
               onSubmit={handleSendForm}
            >
               {(props: FormikProps<any>) => (
                  <Form>
                     <Box
                        display={"flex"}
                        sx={{ px: 4, py: 2, minWidth: 950, flexDirection: "column", alignItems: "center", mx: 4 }}
                     >
                        <Box display={"flex"} sx={{ justifyContent: "flex-end", width: "100%" }}>
                           <Button variant="contained" sx={{ mb: 2 }} onClick={() => handleOpenEmptyInterview()}>
                              Descargar formato
                           </Button>
                        </Box>
                        <Box
                           sx={{
                              width: "100%",
                              borderRadius: 2,
                              bgcolor: "#FFFFFF",
                              border: 1,
                              borderColor: "#8A95A0",
                              mb: 2,
                           }}
                        >
                           <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", p: 2 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h5" sx={{ mb: 1 }}>
                                 Evidencia
                              </Typography>
                           </Grid>
                           <Divider sx={{ mx: 2, mb: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    ¿La entrevista es por vía electrónica?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="online" id="online" row={true} setfile={setUploadedLogoBlob} />
                              </Grid>
                           </Grid>
                           {props.values.online === "Si" ? (
                              <Box py={2} px={6}>
                                 <Box display="flex" justifyContent="space-evenly" alignItems="center">
                                    <Box>
                                       <Typography>Evidencia</Typography>{" "}
                                       <Typography fontSize={11}>Solo archivos .png y .jpeg</Typography>
                                    </Box>

                                    <Button variant="outlined" component="span" sx={{ p: 1, ml: 1 }}>
                                       <input
                                          accept="image/png, image/jpeg, image/jpg"
                                          id="logo-button-file"
                                          // multiple
                                          // value={uploadedLogoBlob ? uploadedLogoBlob.name : ""}
                                          type="file"
                                          onChange={(e) => {
                                             if (e.target.files) setUploadedLogoBlob(e.target.files[0]);
                                          }}
                                       />
                                    </Button>
                                 </Box>
                                 {uploadedLogoBlob ? (
                                    <Box
                                       alignItems="center"
                                       justifyContent="space-between"
                                       display="flex"
                                       bgcolor="whitesmoke"
                                       m={1}
                                       px={2}
                                       borderRadius={1}
                                    >
                                       <Box
                                          component="img"
                                          src={URL.createObjectURL(uploadedLogoBlob)}
                                          maxHeight={90}
                                          maxWidth={180}
                                          sx={{ alignSelf: "center", borderRadius: 1, m: 1, p: 1 }}
                                       />
                                       <IconButton
                                          onClick={() => {
                                             setUploadedLogoBlob(null);
                                          }}
                                       >
                                          <ClearIcon sx={{ color: "#C60000" }} />
                                       </IconButton>
                                    </Box>
                                 ) : (
                                    <></>
                                 )}
                              </Box>
                           ) : null}
                        </Box>
                        <Box
                           sx={{
                              width: "100%",
                              backgroundColor: "#ffffff",
                              borderRadius: 2,
                              border: 1,
                              borderColor: "#8A95A0",
                           }}
                        >
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h5" sx={{ mb: 1 }}>
                                    Formato de entrevista a denunciante
                                 </Typography>
                                 <Typography color={"#2d4357"} fontWeight={500} variant="body1">
                                    La razón de esta entrevista es colaborar con la investigación de los hechos,
                                    conforme un acopio de información de cualquier denuncia existente en el canal de
                                    denuncias.
                                 </Typography>
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    1. ¿Cuál es su nombre completo?
                                 </Typography>
                              </Grid>
                              <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    size="medium"
                                    name="complainantName"
                                    id="complainantName"
                                    type="text"
                                    fullWidth={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    2. ¿Usted labora en la organización?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="complainantLabors" id="complainantLabors" row={true} />
                              </Grid>
                           </Grid>

                           {props.values.complainantLabors === "Si" ? (
                              <>
                                 <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                    <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                       <Typography fontWeight={500} variant="body1">
                                          a. ¿Cuál es la actividad que realiza en la organización?
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          variant="standard"
                                          multiline={true}
                                          size="medium"
                                          name="complainantActivity"
                                          id="complainantActivity"
                                          type="text"
                                          fullWidth={true}
                                       />
                                    </Grid>
                                 </Grid>
                                 <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                    <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                       <Typography fontWeight={500} variant="body1">
                                          b. ¿Cuánto tiempo tiene trabajando en esta organización?
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          variant="standard"
                                          multiline={true}
                                          size="medium"
                                          name="complainantTime"
                                          id="complainantTime"
                                          type="text"
                                          fullWidth={true}
                                       />
                                    </Grid>
                                 </Grid>
                              </>
                           ) : (
                              <></>
                           )}
                           {props.values.complainantLabors === "No" ? (
                              <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                 <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                    <Typography fontWeight={500} variant="body1">
                                       a. ¿Por qué razón se encontraba en el lugar de los hechos, motivo de su denuncia?
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       size="medium"
                                       name="complainantExplanation"
                                       id="complainantExplanation"
                                       type="text"
                                       fullWidth={true}
                                    />
                                 </Grid>
                              </Grid>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    3. ¿Usted se declara como la persona denunciante en el canal de denuncias?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton
                                    name="complainantIsComplainter"
                                    id="complainantIsComplainter"
                                    row={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    4. ¿Alguien más está involucrado o es consciente de la irregularidad denunciada?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="complainantInvolved" id="complainantInvolved" row={true} />
                              </Grid>
                           </Grid>
                           {props.values.complainantInvolved === "Si" ? (
                              <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                 <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                    <Typography fontWeight={500} variant="body1">
                                       a. ¿Podría proceder a narrar la afirmación?
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       size="medium"
                                       name="complainantInvolvedNarration"
                                       id="complainantInvolvedNarration"
                                       type="text"
                                       fullWidth={true}
                                    />
                                 </Grid>
                              </Grid>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    5. ¿Usted asegura que lo ocurrido sucedió de esa manera?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton
                                    name="complainantConfirmation"
                                    id="complainantConfirmation"
                                    row={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    6. ¿Cuenta con algún testigo que respalde su narración?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="complainantWitness" id="complainantWitness" row={true} />
                              </Grid>
                           </Grid>
                           {props.values.complainantWitness === "Si" ? (
                              <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                 <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                    <Typography fontWeight={500} variant="body1">
                                       a. ¿Podría mencionarlos?
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       size="medium"
                                       name="complainantWitnessAnswer"
                                       id="complainantWitnessAnswer"
                                       type="text"
                                       fullWidth={true}
                                    />
                                 </Grid>
                              </Grid>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    7. ¿Se declara como víctima principal de lo ocurrido?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="complainantMainVictim" id="complainantMainVictim" row={true} />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    8. ¿Cuenta con alguna evidencia para probar su dicho, que pueda aportar en el
                                    presente proceso de investigación?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="complainantEvidence" id="complainantEvidence" row={true} />
                              </Grid>
                           </Grid>
                           {props.values.complainantEvidence === "Si" ? (
                              <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                 <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                    <Typography fontWeight={500} variant="body1">
                                       a. ¿Puede proporcionar tales evidencias?
                                    </Typography>
                                 </Grid>
                                 <Grid
                                    item
                                    xs={4}
                                    display={"flex"}
                                    sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                                 >
                                    <InputRadioButton
                                       name="complainantGivesEvidence"
                                       id="complainantGivesEvidence"
                                       row={true}
                                    />
                                 </Grid>
                              </Grid>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    9. ¿Qué medidas serían las idóneas para usted para la erradicación de estos
                                    problemas dentro de la organización?
                                 </Typography>
                              </Grid>
                              <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    size="medium"
                                    name="complainantMeasures"
                                    id="complainantMeasures"
                                    type="text"
                                    fullWidth={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    10. ¿Cómo podríamos ayudar a resarcir el daño ocurrido?
                                 </Typography>
                              </Grid>
                              <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    size="medium"
                                    name="complainantFixDamage"
                                    id="complainantFixDamage"
                                    type="text"
                                    fullWidth={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    11. ¿Ha respondido todas las preguntas con la verdad?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="complainantTruth" id="complainantTruth" row={true} />
                              </Grid>
                           </Grid>
                        </Box>
                        <Box display={"flex"} sx={{ justifyContent: "flex-end", width: "100%", mb: 5 }}>
                           <Button variant="contained" sx={{ mt: 2, width: "50px" }} type="submit">
                              {isLoading ? <CircularProgress size={25} sx={{ color: "white" }} /> : "Enviar"}
                           </Button>
                        </Box>
                     </Box>
                  </Form>
               )}
            </Formik>
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   py: 2,
   pr: 12,
   pl: 2,
};
