import { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography, CircularProgress, Skeleton } from "@mui/material";
import { TitleAppBar } from "../../../../components/TitleAppBar";
import { capitalizeFirstLetter, getDateAndTimeFromISODate } from "../../../../const/globalConst";
import { anonymousComplainant } from "../../../../lib/DocmunetsTemplates/ConstancyTemplates";
import { createDocumentPdf } from "../../../../lib/uploadDocuments";
import Container from "@mui/material/Container/Container";
import pdfMake from "../../../../const/pdfMakeFonts";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { UserContext } from "../../../../context/UserContext";

export const AnonymousComplainerScreen = () => {
   const { reportNumber } = useParams();
   const { complaint, isLoading, getComplaint } = useContext(ComplaintStepperContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isSendLoading, setSendLoading] = useState<boolean>(false);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const navigate = useNavigate();

   useEffect(() => {
      const fetchComplaint = async () => {
         await getComplaint(reportNumber);
      };
      if (isLoading) fetchComplaint();
      // eslint-disable-next-line
   }, [isLoading]);

   useEffect(() => {
      if (access) {
         navigate("/inicio");
      }
      // eslint-disable-next-line
   }, []);

   const handleSendForm = async () => {
      setSendLoading(true);
      const necesaryDocuments = complaint.documentation.find(
         (doc) => doc.name === `constancia-anonimo-${complaint.reportNumber}.pdf`
      );
      if (!necesaryDocuments) {
         const dd = anonymousComplainant(complaint.reportNumber, complaint.createdAt.toString());
         const pdfDocGenerator = pdfMake.createPdf(dd);
         pdfDocGenerator.getBlob(async (blob) => {
            await createDocumentPdf(
               complaint.reportNumber,
               complaint.companyBranch.company._id,
               `constancia-anonimo-${complaint.reportNumber}`,
               blob
            );
            showSnackBar("Documento creado y subido correctamente.", false);
            setSendLoading(false);
            setTimeout(() => navigate(-1), 1000);
         });
      } else {
         showSnackBar("Este documento ya fue enviado.", true);
         setSendLoading(false);
         setTimeout(() => navigate(-1), 1000);
      }
   };

   return (
      <Box p={2}>
         <TitleAppBar head="Denuncia anónima" isScheduleAppointment={true} />
         <Container maxWidth="xl">
            <Box display={"flex"} sx={{ px: 4, minWidth: 950, flexDirection: "column", alignItems: "center", m: 2 }}>
               <Box sx={{ width: "100%" }}>
                  <Box
                     display={"flex"}
                     sx={{
                        bgcolor: "#FFFFFF",
                        border: 1,
                        borderColor: "#8A95A0",
                        borderRadius: 2,
                     }}
                  >
                     <Grid container>
                        <Grid item xs={4}>
                           <Box display={"flex"} sx={dataFieldSX}>
                              <Typography fontWeight={500} variant="body1">
                                 Reporte de denuncia
                              </Typography>
                              <Typography variant="subtitle1">{reportNumber}</Typography>
                           </Box>
                        </Grid>
                        <Grid item xs={4}>
                           <Box display={"flex"} sx={dataFieldSX}>
                              <Typography fontWeight={500} variant="body1">
                                 Fecha de interposición
                              </Typography>
                              <Typography variant="subtitle1">
                                 {getDateAndTimeFromISODate(complaint.createdAt)}
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item xs={4}>
                           <Box display={"flex"} sx={dataFieldSX}>
                              <Typography fontWeight={500} variant="body1">
                                 Estatus
                              </Typography>
                              {complaint.reportNumber !== "" ? (
                                 <Typography variant="subtitle1">{capitalizeFirstLetter(complaint.status)}</Typography>
                              ) : (
                                 <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
                              )}
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
                  <Box display={"flex"} sx={{ justifyContent: "flex-end" }}>
                     <Button variant="contained" sx={{ mt: 2, width: "50px" }} onClick={() => handleSendForm()}>
                        {isSendLoading ? <CircularProgress size={25} sx={{ color: "white" }} /> : "Enviar"}
                     </Button>
                  </Box>
               </Box>
            </Box>
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   py: 2,
   px: 4,
};
