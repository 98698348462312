import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { CustomModalComponent } from "../../../../../CustomModalComponent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EmployerRegistryUsersTable from "./EmployerRegistryUsersTable";
import { useState } from "react";

const EmployerUserSection = () => {
   const [openRegister, setOpenRegister] = useState(false);
   const handleOpenRegister = () => setOpenRegister(true);

   return (
      <Box bgcolor={"#f9f9f9"} boxShadow={2} borderRadius={1} p={2}>
         <Stack direction={"row"} display={"flex"} justifyContent={"space-between"} mb={1}>
            <Typography fontWeight={600} alignSelf={"center"}>
               Usuarios del módulo
            </Typography>
            <Box>
               <Button
                  onClick={handleOpenRegister}
                  sx={{
                     bgcolor: "#162c44",
                     color: "white",
                     columnGap: 0.5,
                     ":hover": {
                        bgcolor: "#162c44",
                     },
                  }}
               >
                  <AddCircleIcon fontSize="small" />
                  <Typography>Agregar usuario</Typography>
               </Button>
            </Box>
         </Stack>
         <Divider />
         <EmployerRegistryUsersTable />
         {/* <CustomModalComponent open={openRegister} setOpen={setOpenRegister} title="Agregar registro patronal">
            <EmployerRegistryModal setOpen={setOpenRegister} />
         </CustomModalComponent> */}
      </Box>
   );
};

export default EmployerUserSection;
