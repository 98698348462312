import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, Typography, CircularProgress, Skeleton } from "@mui/material";
import { TitleAppBar } from "../../../../components/TitleAppBar";
import { proofOfNonExistence } from "../../../../lib/DocmunetsTemplates/ConstancyTemplates";
import { capitalizeFirstLetter, getDateAndTimeFromISODate } from "../../../../const/globalConst";
import { createDocumentPdf } from "../../../../lib/uploadDocuments";
import Container from "@mui/material/Container/Container";
import { SnackBarContext } from "../../../../context/snackBarContext";
import pdfMake from "../../../../const/pdfMakeFonts";
import { UserContext } from "../../../../context/UserContext";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";

export const InexistentConstancyScreen = () => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { getComplaint, isLoading, complaint } = useContext(ComplaintStepperContext);
   const { reportNumber, towards } = useParams();
   const [isSendLoading, setSendLoading] = useState<boolean>(false);
   const [towardsValue, setTowardsValue] = useState("");
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Usuarios");
   const navigate = useNavigate();

   useEffect(() => {
      const fetchComplaint = async () => {
         await getComplaint(reportNumber);
      };
      if (isLoading) fetchComplaint();
      // eslint-disable-next-line
   }, [isLoading]);

   useEffect(() => {
      if (access) {
         navigate("/inicio");
      }
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      if (towards === "1") {
         setTowardsValue("testigo");
      }
      if (towards === "2") {
         setTowardsValue("denunciado");
      }
      if (towards === "3") {
         setTowardsValue("denunciante");
      }
      // eslint-disable-next-line
   }, []);

   const handleSendForm = async () => {
      if (towardsValue) {
         setSendLoading(true);
         const necesaryDocuments = complaint.documentation.some(
            (doc) => doc.name === `constancia-inexistencia-${towardsValue}-${complaint.reportNumber}.pdf`
         );
         if (!necesaryDocuments) {
            const dd = proofOfNonExistence(complaint.reportNumber, towardsValue, complaint.createdAt.toString());
            const pdfDocGenerator = pdfMake.createPdf(dd);
            pdfDocGenerator.getBlob(async (blob) => {
               await createDocumentPdf(
                  complaint.reportNumber,
                  complaint.companyBranch.company._id,
                  `constancia-inexistencia-${towardsValue}-${complaint.reportNumber}`,
                  blob
               );
               showSnackBar("Documento creado y subido correctamente.", false);
               setSendLoading(false);
               setTimeout(() => navigate(-1), 1000);
            });
         } else {
            showSnackBar("Este documento ya fue enviado.", true);
            setTimeout(() => navigate(-1), 1000);
         }
      }
   };

   return (
      <Box p={2}>
         <TitleAppBar head="Constancia de inexistencia" isScheduleAppointment={true} />
         <Container maxWidth="xl">
            {!isLoading ? (
               <>
                  <Box
                     display={"flex"}
                     sx={{ px: 4, minWidth: 950, flexDirection: "column", alignItems: "center", m: 2 }}
                  >
                     <Box sx={{ width: "100%" }}>
                        <Box
                           display={"flex"}
                           sx={{
                              bgcolor: "#ffffff",
                              border: 1,
                              borderColor: "#8A95A0",
                              borderRadius: 2,
                           }}
                        >
                           <Grid container>
                              <Grid item xs={4}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <Typography fontWeight={500} variant="body1">
                                       Reporte de denuncia
                                    </Typography>
                                    <Typography variant="subtitle1">{reportNumber}</Typography>
                                 </Box>
                              </Grid>
                              <Grid item xs={4}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <Typography fontWeight={500} variant="body1">
                                       Fecha de interposición
                                    </Typography>
                                    {complaint.reportNumber !== "" ? (
                                       <Typography variant="subtitle1">
                                          {getDateAndTimeFromISODate(complaint.createdAt)}
                                       </Typography>
                                    ) : (
                                       <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
                                    )}
                                 </Box>
                              </Grid>
                              <Grid item xs={4}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <Typography fontWeight={500} variant="body1">
                                       Involucrado
                                    </Typography>
                                    <Typography variant="subtitle1">{capitalizeFirstLetter(towardsValue)}</Typography>
                                 </Box>
                              </Grid>
                           </Grid>
                        </Box>
                        <Box display={"flex"} sx={{ justifyContent: "flex-end" }}>
                           <Button variant="contained" sx={{ mt: 2, width: "50px" }} onClick={() => handleSendForm()}>
                              {isSendLoading ? <CircularProgress size={25} sx={{ color: "white" }} /> : "Enviar"}
                           </Button>
                        </Box>
                     </Box>
                  </Box>
               </>
            ) : (
               <Box display={"flex"} sx={{ flex: 1, justifyContent: "center", pt: 2 }}>
                  <CircularProgress />
               </Box>
            )}
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   py: 2,
   px: 2,
};
