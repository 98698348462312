import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmployerRegistrySection from "./EmployerRegistry/EmployerRegistrySection";
import EmployerUserSection from "./EmployerRegistry/EmployerUsersSection";
import { ServiceStatusComponent } from "../../../../ClientInformation/ServiceStatusComponent";

export const SirocModuleInfo = () => {
    return (
        <Box bgcolor={"#ffffff"} borderRadius={2} my={2} p={2}>
          <div>
            <Typography mt={2} fontWeight={600}>SIROC</Typography>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color={"#969696"} component="span">
                  Información del módulo
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid bgcolor={"#f9f9f9"} mt={1} container spacing={3}>
                  <Grid item xs={4}>
                    <Typography fontWeight={600}>Campo de info:</Typography>
                    <Typography>Descripciónasd</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography fontWeight={600}>Sector económico:</Typography>
                    <Typography>Servicios</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography fontWeight={600}>Fecha de registro:</Typography>
                    <Typography>13-06-23</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography fontWeight={600}>Formulario inicial:</Typography>
                    <Typography>Descripción</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography fontWeight={600}>Estado:</Typography>
                    <Typography>Sonora</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography fontWeight={600}>Sucursales:</Typography>
                    <Typography>03</Typography>
                  </Grid>
                </Grid>
                <EmployerRegistrySection />
                <EmployerUserSection />
                <ServiceStatusComponent />
              </AccordionDetails>
            </Accordion>             
          </div>
        </Box>
      );
}
