import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useState } from "react";
import EmployerRegistryTable from "./EmployerRegistryTable";
import { CustomModalComponent } from "../../../../../CustomModalComponent";
import { EmployerRegistryModal } from "./EmployerRegistryModal";

const EmployerRegistrySection = () => {
  const [openRegister, setOpenRegister] = useState(false);

  const handleOpenRegister = () => setOpenRegister(true);
  return (
    <Box bgcolor={"#f9f9f9"} boxShadow={2} borderRadius={1} p={2}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
        mb={1}
      >
        <Typography fontWeight={600} alignSelf={"center"}>
          Registro patronal
        </Typography>
        <Box>
          <Button
            onClick={handleOpenRegister}
            sx={{
              bgcolor: "#5D6D7C",
              color: "white",
              columnGap: 0.5,
              ":hover": {
                bgcolor: "#5D6D7C",
              },
            }}
          >
            <AddCircleIcon fontSize="small" />
            <Typography>Agregar registro</Typography>
          </Button>
        </Box>
      </Stack>
      <Divider />
      <EmployerRegistryTable />
      <CustomModalComponent
        open={openRegister}
        setOpen={setOpenRegister}
        title="Agregar registro patronal"
      >
        <EmployerRegistryModal setOpen={setOpenRegister} />
      </CustomModalComponent>
    </Box>
  );
};

export default EmployerRegistrySection;
